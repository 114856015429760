

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
@keyframes delayingActive {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes delayingActive50 {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}