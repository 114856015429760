@keyframes pulse {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #0000004d;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #0000;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #0000;
  }
}

@keyframes delayingActive {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes delayingActive50 {
  from {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
}

/*# sourceMappingURL=index.facc4081.css.map */
